@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.sign-in-button {
  background: linear-gradient(90deg, #e4a61a 0%, #2a2e45 100%);
  width: 100%;
  height: 66px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  /* fill: linear-gradient(90deg, #E4A61A 0%, #2A2E45 100%); */
  /* filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.05)); */
}
.register-button {
  background: linear-gradient(90deg, #e4a61a 0%, #2a2e45 100%);
  width: 100%;
  height: 66px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  /* fill: linear-gradient(90deg, #E4A61A 0%, #2A2E45 100%); */
  /* filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.05)); */
}
.my-scroller::-webkit-scrollbar {
  display: none;
}
.edit-profile {
  border-radius: 50px;
  background: rgba(228, 165, 14, 0.24);
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  margin-top: 20px;
}

.training-box {
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(46deg, #07203e 58.85%, #e4a40c 100%);
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}
.training-boxsm {
  height: 177px;
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(46deg, #07203e 58.85%, #e4a40c 100%);
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}
.training-box1 {
  width: 223px;
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(46deg, #07203e 58.85%, #e4a40c 100%);
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}
.training-box2 {
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(46deg, #0f2743 48.96%, #e5a50e 100%);

  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}
.training-box3 {
  flex-shrink: 0;
  border-radius: 15px;

  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}
.registered-box {
  width: 223px;
  height: 177px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  margin-top: 14px;
}
.registered-boxsm {
  height: 177px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  margin-top: 14px;
}
.registered-box1 {
  width: 223px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
  padding: 20px;
}

.MuiSlider-colorPrimary {
  color: orange !important;
  height: 8px !important;
}
.MuiSlider-mark {
  height: 4px !important;
  width: 4px !important;
  border-radius: 50% !important;
}
.search-input {
  border-radius: 15px;
  background: rgba(204, 201, 201, 0.49);
  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
}

.product-work {
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.download-certificate {
  background: linear-gradient(90deg, #e4a61a 0%, #2a2e45 100%);
  filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.05));
}
.jWkLDY {
  justify-content: space-around !important;
}
.guZdik {
  min-width: 60% !important;
  height: 120px !important;
  border: 2px dashed #dee2e6 !important;
}

/* CustomScrollbar.css */
.custom-scroll {
  max-height: 500px !important;
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #0d1d34 #f3f4f6; /* For Chrome and Edge */
}

/* For Webkit-based browsers like Safari */
.custom-scroll::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}
.fileuploaderwiwdth input {
  width: 100px;
}

.overlay-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Change the opacity value if needed */
  z-index: 10;
}

.loading_overlay_white {
  position: relative;
}
