.training-box2 {
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(46deg, #0f2743 48.96%, #e5a50e 100%);

  box-shadow: 0px 20px 100px 0px rgba(85, 85, 85, 0.05);
}

.training-box3 {
  flex-shrink: 0;
  border-radius: 15px;
}

.button-bg {
  background: linear-gradient(46deg, #0f2743 48.96%, #e5a50e 100%);
}

.container-graph {
  height: 260px !important;
}

.container-graph canvas {
  width: 100% !important;
  height: 100% !important;
}
